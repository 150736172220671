/**
 * Copyright ©2024 Drivepoint
 */

import {initializeApp, FirebaseApp} from "firebase/app";
import {getAuth, Auth, User} from "firebase/auth";
import {Config, EventBus, State} from "@bainbridge-growth/node-frontend";
import DrivepointUser from "./DrivepointUser.ts";
import ServerSentEventService from "./ServerSentEventService.ts";
import ModelSettings from "./microsoft/ModelSettings.ts";

export default class Firebase {

  private static _app: FirebaseApp;
  private static _auth: Auth;
  private static _token: string | undefined;

  static async start(): Promise<void> {
    Firebase._app = initializeApp(Config.get("firebase"), `drivepoint-excel-add-in.${Config.get("environment", "unknown")}`);
    Firebase._auth = getAuth(Firebase.app);
    Firebase.auth.onAuthStateChanged(user => Firebase.onChange(user, "onAuthStateChanged"));
    Firebase.auth.onIdTokenChanged(user => Firebase.onChange(user, "onIdTokenChanged"));
    //setInterval(() => { Firebase.user?.getIdToken(); }, English.ms("1m"));
  }

  static async onChange(user: User | null, event: "onAuthStateChanged" | "onIdTokenChanged"): Promise<void> {
    logger.debug(event, user);
    EventBus.dispatch({type: "user:loading"} as any);
    Firebase._token = undefined;
    if (user) {
      try {
        Firebase._token = await Firebase.user?.getIdToken();
        const settings = await ModelSettings.get();
        const drivepointUser = new DrivepointUser(user);
        await drivepointUser.load();
        State.set("user", drivepointUser);
        State.set("company", drivepointUser.companies.find(it => it.id === settings?.companyId));
        State.set("token", Firebase.token);
        ServerSentEventService.connect();
        EventBus.dispatch({type: "user:loaded"} as any);
        return;
      } catch (error: any) {
        logger.error(error.message);
        EventBus.dispatch({type: "system:error", message: "An unexpected error occurred during authentication."} as any);
      }
    }
    // we fall through here if no user or an error happened above
    ServerSentEventService.disconnect();
    State.set("user", null);
    State.set("company", null);
    State.set("token", null);
    EventBus.dispatch({type: "user:loaded"} as any);
  }

  static get app(): FirebaseApp {
    return Firebase._app;
  }

  static get auth(): Auth {
    return Firebase._auth;
  }

  static get user(): User | null {
    return Firebase.auth.currentUser;
  }

  static get token(): string | undefined {
    return Firebase._token;
  }

  static get bearer(): string {
    const token = Firebase.token;
    return token ? `Bearer ${token}` : "";
  }

}
