/**
 * Copyright ©2024 Drivepoint
 */

import {Route, Routes, useNavigate} from "react-router-dom";
import React, {createContext, Suspense, useEffect, useRef, useState} from "react";
import {CircularProgress} from "@mui/material";
import {EventBus, State, useStateChange, useStore} from "@bainbridge-growth/node-frontend";
import NotFoundPage from "../../pages/NotFoundPage.tsx";
import SignInPage from "../../pages/SignInPage/SignInPage.tsx";
import DrivepointUser from "../../services/DrivepointUser.ts";
import ModelSettings from "../../services/microsoft/ModelSettings.ts";
import Pages from "../../services/Pages.ts";
import ServerSentEventService from "../../services/ServerSentEventService.ts";
import Telemetry from "../../services/telemetry/Telemetry.ts";
import PlanStore from "../../stores/PlanStore.ts";
import type {PlanProps} from "../../types/ExcelModel.ts";
import ErrorHandling from "../../utilities/ErrorHandling.ts";
import DPNotificationWrapper from "../DPNotificationWrapper/DPNotificationWrapper.tsx";
import DPPageStatus, {DPPageStatusInterface} from "../DPPageStatus/DPPageStatus.tsx";
import Footer from "../Footer/Footer.tsx";
import Header, {HeaderInterface} from "../Header/Header.tsx";
import Page from "../Page/Page.tsx";
import "./App.css";

type MainContextProps = {
  updateHeaderTitle?: HeaderInterface["updateTitle"];
  updateHeaderAction?: HeaderInterface["updateAction"];
  disableMenuButton?: HeaderInterface["disableMenuButton"];
};

export const MainContext = createContext<MainContextProps>({});

export default function App() {

  const header = useRef<HeaderInterface>();
  const status = useRef<DPPageStatusInterface>();

  const navigate = useNavigate();
  const [_, planStore] = useStore<PlanProps, PlanStore>(PlanStore);

  const officeReady = useStateChange<boolean>("office:ready");
  const user = useStateChange<DrivepointUser>("user");

  const [context, setContext] = useState<MainContextProps>(null);

  useEffect(() => {
    const registrations = EventBus.registerMany("show:page", "state:addin:visibility", "WorksheetChanged", "WorksheetNameChanged", async (event: any) => {
      if (event.type === "show:page") {
        const page = Pages.getPageByPath(event.id);
        if (page) { Telemetry.track("page_change", {page_id: page.id, name: page.name, path: page.path}); }
        navigate(event.id);
      }
      if (event.type === "state:addin:visibility") { Telemetry.track("addin_visibility", {visible: event.value}); }
      if (event.type === "WorksheetChanged") {
        if (event.worksheet?.name === "Settings") { onSettingsChanged(); }
      }
      if (event.type === "WorksheetNameChanged" && (event.nameAfter === "Settings" || event.nameBefore === "Settings")) {
        ModelSettings.clear();
        onSettingsChanged();
      }
    });
    return () => {
      EventBus.unregister(...registrations);
    };
  }, []);

  useEffect(() => {
    if (user) {
      setContext({
        updateHeaderAction: header.current.updateAction,
        updateHeaderTitle: header.current.updateTitle,
        disableMenuButton: header.current.disableMenuButton
      });
    }
  }, [user]);

  useEffect(() => {
    if (!status.current || !planStore.error) { return; }
    const message = ErrorHandling.getHumanReadableErrorMessage(planStore.error);
    status.current?.show({
      variant: "error",
      title: "Something went wrong!",
      subtitle: `${message}. Please try again. If the issue persists please contact support.`,
      actions: [
        {icon: "refresh", title: "Refresh Add-in", click: () => window.location.reload()},
        {icon: "support_agent", title: "Contact Support", click: ErrorHandling.contactSupport, notHideAfter: true}
      ]
    });
  }, [planStore.error, status.current]);

  async function onSettingsChanged(): Promise<void> {
    ModelSettings.clear();
    const settings = await ModelSettings.get();
    const company = State.get("company");
    if (!settings?.companyId || settings?.companyId !== company?.id) {
      const user = State.get("user");
      await ServerSentEventService.disconnect();
      State.set("company", user?.companies?.find(it => it.id === settings?.companyId));
      await ServerSentEventService.connect();
    }
  }

  function renderOfficeLoading(): any {
    return <div className="app-office-loading">
      <CircularProgress />
    </div>;
  }

  function renderPageRoute(page: any): any {
    const routes: any[] = [<Route key={page.id} path={page.path} element={<Suspense><page.element /></Suspense>} />];
    if (page.default) {
      routes.push(<Route key={`${page.id}_default`} path="/" element={<Suspense><page.element /></Suspense>} />);
      routes.push(<Route key={`${page.id}_index`} index element={<Suspense><page.element /></Suspense>} />);
    }
    return routes;
  }

  function renderPageRoutes(): any {
    return Pages.pages.map(renderPageRoute);
  }

  function renderDefaultPage(): any {
    const page = Pages.pages[0];
    if (page?.id) {
      return <>
        routes.push(<Route key={`${page.id}_default`} path="/" element={<Suspense><page.element /></Suspense>} />);
        routes.push(<Route key={`${page.id}_index`} index element={<Suspense><page.element /></Suspense>} />);
      </>;
    }
    return <Route path="*" element={<NotFoundPage />} />;
  }

  function renderRoutes(): any {
    return <Routes>
      {renderPageRoutes()}
      {renderDefaultPage()}
    </Routes>;
  }

  logger.debug("renderContent", officeReady, user, planStore.error);

  function renderContent(): any {
    // if user is undefined, Firebase is still initializing:
    if (!officeReady || user === undefined) { return renderOfficeLoading(); }
    if (!user) { return <SignInPage />; }
    if (planStore.error) { return <div />; } // error will be displayed in DPPageStatus overlay
    return <>
      {user && <Header ref={header} />}
      <MainContext.Provider value={context}>
        <Page>{renderRoutes()}</Page>
      </MainContext.Provider>
      <Footer />
    </>;
  }

  function render(): any {
    return <>
      <DPPageStatus ref={status} />
      <DPNotificationWrapper>
        {renderContent()}
      </DPNotificationWrapper>
    </>;
  }

  return <div className="app">
    {render()}
  </div>;

}
