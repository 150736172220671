/**
 * Copyright ©2024 Drivepoint
 */

import React, {Fragment, useMemo} from "react";
import dayjs from "dayjs";

import {useStateChange, useStore} from "@bainbridge-growth/node-frontend";
import {
  Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Box, Icon,
  List, ListItem, ListItemIcon, ListItemText, Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import dataSourcesMap from "../../../pages/ImportDataPage/DataSourceMap.ts";
import DataFreshnessStore from "../../../stores/DataFreshnessStore.ts";
import type {DataSourceInfo} from "../../../types/DataSource.ts";
import DPButton from "../../DPButton/DPButton.tsx";
import DPDivider from "../../DPDivider/DPDivider.tsx";

type DPDataFreshness = {
  isAlert?: boolean;
  showDataFreshnessDetails?: boolean;
};

export default function DPDataFreshness({isAlert = false, showDataFreshnessDetails = false}: DPDataFreshness) {

  const navigate = useNavigate();

  const [dataFreshness] = useStore<any, DataFreshnessStore>(DataFreshnessStore);
  const data_import_info = useStateChange<DataSourceInfo[]>("data_imports_info");
  
  const dataImportToDWHAt = useMemo(() => {
    if (dataFreshness.length === 0) { return; }
    
    return dayjs.max(dataFreshness
      .map((el: Record<string, any>) => el?.last_record_created_at?.value)
      .filter((it: string | null | undefined) => it)
      .map((el: string) => dayjs(el))).format("MMM DD, YYYY hh:mm A");
    
  }, [dataFreshness]);

  const isInProgress = useMemo(() => {
    if (!data_import_info) {return false;}

    return data_import_info.some((el: DataSourceInfo) => el.import_status === "progress");
  }, [data_import_info]);

  const latestImportAllDataInfo = useMemo(() => {
    if (!data_import_info || data_import_info.length === 0) {return;}

    const allRefreshedAt = data_import_info.map((el: DataSourceInfo) => dayjs(el.updated_at));

    if (data_import_info.some((el: DataSourceInfo) => !el.updated_at) || isInProgress) {
      return null;
    }

    return <>Last full data import <b>{dayjs.min(allRefreshedAt).format("MMM DD, YYYY hh:mm A")}</b>. </>;
  }, [data_import_info]);
  
  function renderDataFreshNessContent() {
    return <Typography variant="body2">
      {latestImportAllDataInfo}
      {dataFreshness.length > 0 && <>Latest data from data warehouse is from <b>{dataImportToDWHAt}.</b></>}
    </Typography>;
  }

  function renderSecondaryTextForAccordionSubList(updatedAt: string | undefined, importedBy: string): string | undefined {
    if (!updatedAt) {return;}

    if (updatedAt) {
      return `Imported ${dayjs(updatedAt).fromNow()}. Imported by ${importedBy}`;
    }

  }

  function renderDataFreshnessAccordion() {
    if (data_import_info && data_import_info?.length > 0) {
      return <Box width="1">
        {data_import_info.map((dataSource: DataSourceInfo) => <Accordion key={dataSource.id} style={{background: "transparent", boxShadow: "none"}}>
          <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
            <ListItem>
              <ListItemIcon><img alt="data_logo" src={dataSourcesMap[dataSource.id].icon} /></ListItemIcon>
              <ListItemText>{dataSource.name}</ListItemText>
            </ListItem>
          </AccordionSummary>
          {<AccordionDetails style={{background: "var(--GREY-0)"}}>
            {dataSource?.updated_at && <Box>
              <Typography color="var(--GREY-600)" variant="body2">{dataSource.name} was last imported {dayjs(dataSource.updated_at).fromNow()}</Typography>
              <Typography color="var(--GREY-600)" variant="body2">By {dataSource.import_by}</Typography>
            </Box>}
            <List>
              {(dataSource.items ?? [])
                .map((item, index) =>
                  <Fragment key={index}>
                    <ListItem>
                      <ListItemIcon><Icon>grid_on</Icon></ListItemIcon>
                      <ListItemText primary={item.tab} secondary={renderSecondaryTextForAccordionSubList(item.updated_at, item.import_by)} />
                    </ListItem>
                    {index !== dataSource.items.length - 1 && <DPDivider/>}
                  </Fragment>
                )}
            </List>
          </AccordionDetails>}
        </Accordion>)}
        <DPButton
          fullWidth
          style={{marginTop: "10px"}}
          label="Go To Data Import"
          onClick={() => {navigate("/ImportDataPage");}} />

      </Box>;
    }

    return <></>;
  }
  
  if (isAlert && ((data_import_info ?? []).length > 0 || (dataFreshness ?? []).length > 0)) {
    return <Alert severity={isInProgress ? "warning" : "info"} sx={{
      ".MuiAlert-message": {width: "100%"}
    }}>
      <AlertTitle>{isInProgress ? "Data Import in Progress" : "Data Freshness"}</AlertTitle>
      {renderDataFreshNessContent()}
      {showDataFreshnessDetails && renderDataFreshnessAccordion()}
    </Alert>;
  }
    
  return <>{renderDataFreshNessContent()}</>;
}